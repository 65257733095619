.carousel-image-wrapper {
    position: relative;
    z-index: 1;
    width: 350px;
    height: 200px;
    overflow: hidden;
    border-radius: 8px;
}

.carousel-image-shadow {
    position: absolute;
    padding: 8px;
    width: 100%;
    height: 100%;
    background-image: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,.2)),color-stop(40%,transparent));
    background-image: linear-gradient(rgba(0,0,0,.2),transparent 40%);
    z-index: 1;
}

.carousel-image-item {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 100%;
    width: 100%;
    height: auto;
    -webkit-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0);
}


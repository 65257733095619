.apartment-fields-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-bottom: 0;
    margin-bottom: 2.5rem;
}

.apartment-field-item {
  min-width: 25%;
  width: 25%;
}
